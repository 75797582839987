import React from 'react';
import categories from './data/categories.json'
import cards from './data/cards.json'

const ComparisonTable = (props) => {
  const { data } = props;
  const numOfCols = 1 + cards.length;

  return (
    <div className="overflow-x-auto w-full">
      <div className="overflow-hidden min-w-max">
        <div className="grid grid-cols-5 gap-x-16 p-4 text-sm font-medium text-gray-900 bg-gray-100 border-t border-b border-gray-200">
          <div className="flex items-center"></div>
          { cards.map((item,i) => (
              <div key={i} className="flex items-center">
                <img src={item.image} alt={item.name} className="w-28" />
              </div>
            ))
          }
        </div>

        <div className="grid grid-cols-5 gap-x-16 p-4 text-sm font-medium text-gray-900 bg-gray-100 border-t border-b border-gray-200">
          <div className="flex items-center">Card Name</div>
          { cards.map((item,i) => (
              <div key={i} className="flex items-center">
                { item.name }
              </div>
            ))
          }
        </div>

        <div className="grid grid-cols-5 gap-x-16 py-5 px-4 text-sm text-gray-700 border-b border-gray-200">
          <div className="text-gray-500 capitalize">Total Cashback (Estimated)</div>
          { data.map((item,i) => (
              <div key={i} className="flex items-center">
                <span className="text-green-500">RM{Number(item.cashback).toFixed(2)}</span>
              </div>
            ))
          }
        </div>

        <div className="grid grid-cols-5 gap-x-16 py-5 px-4 text-sm text-gray-700 border-b border-gray-200">
          <div className="text-gray-500 capitalize">Annual Fee</div>
          { data.map((item,i) => (
              <ol key={i} className="list-decimal text-xs ml-4">
                {
                  item.annualFee.length > 0 ? item.annualFee.map((rule,j) => (
                    <li key={j}>
                      {rule}
                    </li>
                  )) : '-'
                }
              </ol>
            ))
          }
        </div>

        <div className="grid grid-cols-5 gap-x-16 py-5 px-4 text-sm text-gray-700 border-b border-gray-200">
          <div className="text-gray-500 capitalize">Rules</div>
          { data.map((item,i) => (
              <ol key={i} className="list-decimal text-xs ml-4">
                {
                  item.rules.length > 0 ? item.rules.map((rule,j) => (
                    <li key={j}>
                      {rule}
                    </li>
                  )) : '-'
                }
              </ol>
            ))
          }
        </div>
      </div>
    </div>
  );
}

export default ComparisonTable;

import './App.css';
import CategorySlider from './CategorySlider';
import ComparisonTable from './ComparisonTable';
import { useState, useEffect } from "react";
import cards from './data/cards.json'

function App() {
  const [categorySpent, setCategorySpent] = useState({});
  const [totalSpent, setTotalSpent] = useState("");
  const [cashbackData, setCashbackData] = useState(cards.map((item) => {
    return {
      id: item.id,
      cashback: 0,
      formula: item.formula,
      rules: item.rules,
      annualFee: item.annual_fee
    }
  }));

  function updateValue(category, val) {
    console.log(category, val);
    setCategorySpent({ ...categorySpent, [category]: val });
  }

  function calculateCashback(totalSpent, categorySpent, formula) {
    let cashback = {};

    switch (formula) {
      case "formula1":
        // citibank formula
        const f1CappedCategoryCashback = 10;
        Object.entries(categorySpent).forEach(([key, value]) => {
          if (totalSpent >= 500) {
            if (key === "petrol") {
              let cb = 0.1 * value;
              cashback[key] = cb > f1CappedCategoryCashback ? f1CappedCategoryCashback : cb;
            } else if (key === "groceries") {
              let cb = 0.1 * value;
              cashback[key] = cb > f1CappedCategoryCashback ? f1CappedCategoryCashback : cb;
            } else if (key === "dining") {
              let cb = 0.1 * value;
              cashback[key] = cb > f1CappedCategoryCashback ? f1CappedCategoryCashback : cb;
            } else if (key === "ewallet") {
              let cb = 0.1 * value;
              cashback[key] = cb > f1CappedCategoryCashback ? f1CappedCategoryCashback : cb;
            } else {
              cashback[key] = 0.002 * value;
            }
          } else {
            cashback['other'] = 0.002 * value;
          }
        });

        return Object.values(cashback).reduce((a, b) => a + b, 0);
      case "formula2":
        // affinbank formula
        const f2CappedTotalCashback = 80;
        Object.entries(categorySpent).forEach(([key, value]) => {
          if (key === "online") {
            cashback[key] = 0.03 * value;
          } else if (key === "ewallet") {
            cashback[key] = 0.03 * value;
          } else if (key === "auto-billing") {
            cashback[key] = 0.03 * value;
          }
        });

        const totalCashback = Object.values(cashback).reduce((a, b) => a + b, 0);

        return totalCashback > f2CappedTotalCashback ? f2CappedTotalCashback : totalCashback;
      case "formula3": 
        // UOB formula
        const f3CappedTotalCashback = 30;
        const f3CappedEwalletCashback = 15;
        Object.entries(categorySpent).forEach(([key, value]) => {
          if (totalSpent >= 600) {
            if (key === "online") {
              let cb = 0.05 * value;
              cashback[key] = cb > f3CappedTotalCashback ? f3CappedTotalCashback : cb;
            } else if (key === "ewallet") {
              let cb = 0.05 * value;
              cashback[key] = cb > f3CappedEwalletCashback ? f3CappedEwalletCashback : cb;
            } else if (key === "dining") {
              let cb = 0.05 * value;
              cashback[key] = cb > f3CappedTotalCashback ? f3CappedTotalCashback : cb;
            } else if (key === "contactless") {
              let cb = 0.05 * value;
              cashback[key] = cb > f3CappedTotalCashback ? f3CappedTotalCashback : cb;
            }
          }
        });
        
        const totalF3Cashback = Object.values(cashback).reduce((a, b) => a + b, 0);

        return (totalF3Cashback > f3CappedTotalCashback ? f3CappedTotalCashback : totalF3Cashback) + (totalSpent >= 600 ?  + (cashback['ewallet'] ?? 0) : 0);
      case "formula4": 
        // RHB formula
        const f4AccumulatedCappedCashback = 50;
        let f4AccumulatedCashback = {};
        Object.entries(categorySpent).forEach(([key, value]) => {
          if (totalSpent >= 800 && totalSpent < 1500) {
            if (key === "online" || key === "ewallet" || key === "utilities" || key === "groceries") {
              f4AccumulatedCashback[key] = 0.01 * value;
            } else if (key === "petrol") {
              cashback[key] = 0.05 * value;
            }
          } else if (totalSpent >= 1500 && totalSpent < 2500) {
            if (key === "online" || key === "ewallet" || key === "utilities" || key === "groceries") {
              f4AccumulatedCashback[key] = 0.02 * value;
            } else if (key === "petrol") {
              cashback[key] = 0.08 * value;
            }
          } else if (totalSpent >= 2500) {
            if (key === "online" || key === "ewallet" || key === "utilities" || key === "groceries") {
              f4AccumulatedCashback[key] = 0.05 * value;
            } else if (key === "petrol") {
              cashback[key] = 0.12 * value;
            }
          }
        });

        const totalF4AccumulatedCashback = Object.values(f4AccumulatedCashback).reduce((a, b) => a + b, 0);
        
        const totalF4Cashback = totalF4AccumulatedCashback > f4AccumulatedCappedCashback ? f4AccumulatedCappedCashback : totalF4AccumulatedCashback;

        return totalF4Cashback + ((cashback['petrol'] ?? 0) > 50 ? 50 : (cashback['petrol'] ?? 0));

      default:
        return 0;
    }
  }

  useEffect(() => {
    let total = 0;
    total = Object.values(categorySpent).reduce((acc, curr) =>  acc + curr, 0);
    setTotalSpent(total);

    setCashbackData((c) => {
      return c.map((item) => {
        return {
          ...item,
          cashback: calculateCashback(total, categorySpent, item.formula)
        }
      })
    });
  }, [categorySpent]);

  return (
    <div className="p-4">
      <CategorySlider updateValue={updateValue} />
      <div className="flex justify-center items-center">
        <div className="w-full sm:w-1/2 text-lg font-bold my-4">Total spent: RM{totalSpent}</div>
      </div>
      <div className="flex flex-col text-gray-500">
        <div className="font-bold">Available cards:</div>
        <ol className="list-decimal ml-4">
          <li>Citi Cash Back Mastercard</li>
          <li>AFFIN DUO Visa Cash Back</li>
          <li>UOB YOLO Visa</li>
          <li>RHB Shell Visa</li>
        </ol>
      </div>
      <ComparisonTable data={cashbackData} />
      <div className="flex italic text-gray-400">
        Last updated: {new Date().getDate()}/{new Date().getMonth() + 1}/{new Date().getFullYear()}
      </div>
    </div>
  );
}

export default App;

import { Slider, Row, Col } from 'antd';
import categories from './data/categories.json'
import './App.css';

const CategorySlider = (props) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="w-full sm:w-1/2 font-bold text-lg mb-4">
        Your monthly spending:
      </div>
      {
        categories.map((item,i) => (
          <div className="flex flex-col justify-center w-full sm:w-1/2">
            <span className="capitalize">{item.category}</span>
            <Slider defaultValue={0} step={10} max={item.maxAmount} onAfterChange={val => props.updateValue(item.category,val)} />
          </div>
        ))
      }
    </div>
  );
}

export default CategorySlider;
